import { AddQuizB2BMutation, AddQuizB2BMutationVariables } from '@global/utils/remote-graphql-types';
import { TypedDocumentNode } from '@web/data/apollo-client';
import gql from 'graphql-tag';

export const InsertB2bDiagnosisMutation: TypedDocumentNode<AddQuizB2BMutation, AddQuizB2BMutationVariables> = gql`
  mutation AddQuizB2B($quizB2B: b2b_diagnosis_v1_insert_input!) {
    insert_b2b_diagnosis_v1_one(object: $quizB2B) {
      id
    }
  }
`;
