import { GatsbyButton } from '@components/atm.button/button.component';
import { B2b_Diagnosis_V1_Insert_Input, Mutation_Root } from '@global/utils/remote-graphql-types';
import { Gender } from '@global/utils/domain/entities';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import defaultClient from '@lp-root/src/data/graphql/default-client';
import { InsertB2bDiagnosisMutation } from '@lp-root/src/data/graphql/hasura/b2b-diagnosis-mutation.mutation.hasura';
import { File, Query } from '@lp-root/src/data/graphql/local-graphql-types';
import { PageProps } from '@lp-root/src/utils/local-types';
import { appPaths } from '@lp-root/src/utils/path';
import { Body, Col, FeaturedH2, Grid, H1, InputLabel, Row, Separator } from '@web/atomic';
import { RadioField } from '@web/atomic/legacy/atm.radio';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { TextFieldMasked } from '@web/atomic/legacy/atm.text-field/text-field-masked.component';
import { FlashMessage } from '@web/atomic/legacy/mol.flash-message';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import { useField } from '@web/atomic/obj.custom-hooks/field.hook';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { RelativeWrapper } from '@web/atomic/obj.wrappers';
import { useMutationCustom } from '@web/data/use-mutation-custom.hook';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import React, { useCallback } from 'react';
import CleanLayout from '../components/org.layout/clean-layout.component';
import {
  Anxiety,
  Concerned,
  ControlConcerns,
  Discouragement,
  InitialInsomnia,
  Interference,
  MiddleInsomnia,
  OthersPerception,
  Pleasure,
  Satisfaction,
  SleepDisturb,
  SleepProblemAge,
  TerminalInsomnia,
  WeekFrequency,
} from '../data/quiz-typeform.model';
import {
  AnxietyTitle,
  ConcernedTitle,
  ControlConcernsTitle,
  DiscouragementTitle,
  GenderTitle,
  InitialInsomniaTitle,
  InterferenceTitle,
  mapAnxietyToNumber,
  mapConcernedToNumber,
  mapControlConcernsToNumber,
  mapDiscouragementToNumber,
  mapInitialInsomniaToNumber,
  mapInterferenceToNumber,
  mapMiddleInsomniaToNumber,
  mapOthersPerceptionToNumber,
  mapPleasureToNumber,
  mapSatisfactionToNumber,
  mapTerminalInsomniaToNumber,
  MiddleInsomniaTitle,
  OthersPerceptionTitle,
  PleasureTitle,
  SatisfactionTitle,
  SleepDisturbTitle,
  SleepProblemAgeTitle,
  TerminalInsomniaTitle,
  TermTitle,
  WeekFrequencyTitle,
} from '../modules/quiz-b2b/quiz-b2b-questions';
import { buildQuizB2BMutationVar } from '../modules/quiz-b2b/quiz-typeform.utils';

export interface CustomQuery extends Query {
  girl: File;
}

// TODO: Refactor the return statement
// Why: too big, probably the responsabilities are widespread
// Idea: make subcomponents

const StartForm: React.FunctionComponent<PageProps<unknown, CustomQuery>> = (props) => {
  const factorQuestionThree = 2.8;
  const factorQuestionFourOne = 10;
  const factorQuestionFourTwo = -1;
  const factorQuestionFourThree = 10;
  const minPoinsNecessaryToShowSleepProblemAgeQuestion = 7;

  const [consent, setConsent] = useField(0);

  const [q1_1, computeScoreQ1_1] = useField(0);
  const [q1_2, computeScoreQ1_2] = useField(0);
  const [q1_3, computeScoreQ1_3] = useField(0);
  const [q1_4, computeScoreQ1_4] = useField(0);
  const [q1_5, computeScoreQ1_5] = useField(0);
  const [q1_6, computeScoreQ1_6] = useField(0);
  const [q1_7, computeScoreQ1_7] = useField(0);

  const [q2, computeScoreQ2] = useField<SleepProblemAge>(' ' as any);
  const [q3, computeScoreQ3] = useField(0);
  const [q4, computeScoreQ4] = useField<SleepDisturb>(' ' as any);

  const [, computeScoreQ5_1] = useField(0);
  const [, computeScoreQ5_2] = useField(0);
  const [, computeScoreQ5_3] = useField(0);
  const [, computeScoreQ5_4] = useField(0);

  const computeScore = () => {
    let score = q1_1 + q1_2 + q1_3 + q1_4 + q1_5 + q1_6 + q1_7;

    // Q3
    score /= factorQuestionThree;

    // Q4_1
    score -= factorQuestionFourOne;

    //Q4_2
    score *= factorQuestionFourTwo;

    //Q4_3
    score *= factorQuestionFourThree;

    return score;
  };

  const showSleepProblemAgeQuestion = q1_1 + q1_2 + q1_3 + q1_4 + q1_5 + q1_6 + q1_7 >= minPoinsNecessaryToShowSleepProblemAgeQuestion;

  const queryParams = useQueryParams<B2b_Diagnosis_V1_Insert_Input>();
  const companyName = queryParams.company ? queryParams.company : null;
  const origin = queryParams.origin;
  const totalScore = Math.ceil(computeScore());

  const [performSubmitRequest, { data: submitData, error, loading: submitLoading }] = useDiagnosisMutation(totalScore.toString(), q2, q4);

  const handleSubmit = useCallback(
    async (formData: FormData<QuizB2BData>) => {
      if (Object.keys(formData.error).length !== 0) return;
      formData.data.company = formData.data.company ?? companyName;
      formData.data.origin = origin;
      const quizB2B = buildQuizB2BMutationVar(formData.data);
      await performSubmitRequest({ variables: { quizB2B } });
    },
    [performSubmitRequest, companyName, origin]
  );

  return (
    <>
      <CleanLayout>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
            title: 'Saiba se a Vigilantes do Sono é pra você',
            description: 'Faça esta avaliação da sua insônia para ver se a Vigilantes do Sono pode te ajudar.',
            image: getSrc(props.data.girl.childImageSharp.gatsbyImageData),
            imageAlt: 'menina com máscara',
          }}
        />

        <Grid>
          <Form onSubmit={handleSubmit}>
            <QuizB2BIntroduction {...props} />
            <ConsentTermQuestion consent={consent} setConsent={setConsent} />
            {consent === ConsentTerm.Accepted && (
              <>
                <NameQuestion />

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <FeaturedH2>
                      {' '}
                      <span role="img" aria-label="instruções">
                        &#9888;&#65039;
                      </span>{' '}
                      Responda as próximas perguntas considerando somente AS DUAS ÚLTIMAS SEMANAS.
                    </FeaturedH2>
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="initialInsomnia"
                      validators={[Validators.Required()]}
                      onValueChange={(value) => computeScoreQ1_1(mapInitialInsomniaToNumber(value))}
                    >
                      <InputLabel>
                        <InitialInsomniaTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(InitialInsomnia).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="middleInsomnia"
                      onValueChange={(value) => computeScoreQ1_2(mapMiddleInsomniaToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <MiddleInsomniaTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(MiddleInsomnia).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>
                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="terminalInsomnia"
                      onValueChange={(value) => computeScoreQ1_3(mapTerminalInsomniaToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <TerminalInsomniaTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(TerminalInsomnia).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="satisfaction"
                      onValueChange={(value) => computeScoreQ1_4(mapSatisfactionToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <SatisfactionTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(Satisfaction).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="interference"
                      onValueChange={(value) => computeScoreQ1_5(mapInterferenceToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <InterferenceTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(Interference).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="othersPerception"
                      onValueChange={(value) => computeScoreQ1_6(mapOthersPerceptionToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <OthersPerceptionTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(OthersPerception).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="concerned"
                      onValueChange={(value) => computeScoreQ1_7(mapConcernedToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <ConcernedTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(Concerned).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                {showSleepProblemAgeQuestion && (
                  <Row mb center="xs">
                    <Col xs={12} sm={10} md={9} lg={8}>
                      <ForcedFade show={true}>
                        <Form.Field name="sleepProblemAge" onValueChange={computeScoreQ2} validators={[Validators.Required()]}>
                          <InputLabel>
                            <SleepProblemAgeTitle />
                          </InputLabel>
                          <Separator />
                          {Object.values(SleepProblemAge).map((value) => (
                            <RadioField key={value} id={value}>
                              {value}
                            </RadioField>
                          ))}
                        </Form.Field>
                      </ForcedFade>
                      <Separator />
                    </Col>
                  </Row>
                )}

                {showSleepProblemAgeQuestion && (
                  <Row mb center="xs">
                    <Col xs={12} sm={10} md={9} lg={8}>
                      <ForcedFade show={true}>
                        <Form.Field name="weekFrequency" onValueChange={computeScoreQ3} validators={[Validators.Required()]}>
                          <InputLabel>
                            <WeekFrequencyTitle />
                          </InputLabel>
                          <Separator />
                          {Object.values(WeekFrequency).map((value) => (
                            <RadioField key={value} id={value}>
                              {value}
                            </RadioField>
                          ))}
                        </Form.Field>
                      </ForcedFade>
                      <Separator />
                    </Col>
                  </Row>
                )}

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field name="sleepDisturb" onValueChange={(value) => computeScoreQ4(value)} validators={[Validators.Required()]}>
                      <InputLabel>
                        <SleepDisturbTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(SleepDisturb).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Separator />
                    <FeaturedH2>
                      {' '}
                      &#9888;&#65039; Ainda sobre os últimos 14 dias, em quantos você foi afetado/a por algum dos seguintes problemas?
                    </FeaturedH2>
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="pleasure"
                      onValueChange={(value) => computeScoreQ5_1(mapPleasureToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <PleasureTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(Pleasure).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="discouragement"
                      onValueChange={(value) => computeScoreQ5_2(mapDiscouragementToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <DiscouragementTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(Discouragement).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="anxiety"
                      onValueChange={(value) => computeScoreQ5_3(mapAnxietyToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <AnxietyTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(Anxiety).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="controlConcerns"
                      onValueChange={(value) => computeScoreQ5_4(mapControlConcernsToNumber(value))}
                      validators={[Validators.Required()]}
                    >
                      <InputLabel>
                        <ControlConcernsTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(ControlConcerns).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                {!companyName && (
                  <Row mb center="xs">
                    <Col xs={12} sm={10} md={9} lg={8}>
                      <Form.Field name="company" validators={[Validators.Required()]}>
                        <InputLabel>
                          Em qual empresa você trabalha?{' '}
                          <span role="img" aria-label="empresa">
                            &#x1F913;
                          </span>
                        </InputLabel>
                        <TextField type={'text'} />
                      </Form.Field>
                      <Separator />
                    </Col>
                  </Row>
                )}

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field name="gender" validators={[Validators.Required()]}>
                      <InputLabel>
                        <GenderTitle />
                      </InputLabel>
                      <Separator />
                      {Object.values(Gender).map((value) => (
                        <RadioField key={value} id={value}>
                          {value}
                        </RadioField>
                      ))}
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field
                      name="cpf"
                      validators={[
                        Validators.Required(),
                        Validators.CpfPatternRegex('Formato inválido de CPF'),
                        Validators.CpfRegex('CPF inválido'),
                      ]}
                    >
                      <InputLabel>Qual é seu CPF?</InputLabel>
                      <TextFieldMasked kind={'cpf'} placeholder={'xxx.xxx.xxx-xx'} />
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center="xs">
                  <Col xs={12} sm={10} md={9} lg={8}>
                    <Form.Field name="birthdate" validators={[Validators.Required()]}>
                      <InputLabel>Qual sua data de nascimento?</InputLabel>
                      <TextFieldMasked kind={'datetime'} placeholder={'dd/mm/aaaa'} options={{ format: 'DD/MM/YYYY' }} />
                    </Form.Field>
                    <Separator />
                  </Col>
                </Row>

                <Row mb center={'xs'}>
                  <Col xs={12} sm={10} md={4} lg={4}>
                    <GatsbyButton type="submit" kind="primary" disabled={!!submitData && !error} loading={submitLoading} expanded>
                      Ver resultados
                    </GatsbyButton>
                    <Separator />
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Grid>
      </CleanLayout>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface QuizB2BData {
  weekFrequency: WeekFrequency;
  name: string;
  anxiety: Anxiety;
  birthdate: string;
  company: string;
  cpf: string;
  gender: string;
  extraData?: JSON;
  sleepDisturb: SleepDisturb;
  othersPerception: OthersPerception;
  interference: Interference;
  satisfaction: Satisfaction;
  concerned: Concerned;
  sleepProblemAge: SleepProblemAge;
  controlConcerns: ControlConcerns;
  discouragement: Discouragement;
  pleasure: Pleasure;
  initialInsomnia: InitialInsomnia;
  middleInsomnia: MiddleInsomnia;
  terminalInsomnia: TerminalInsomnia;
  origin: string;
}

enum ConsentTerm {
  Undefined = 0,
  Accepted = 1,
  Rejected = 2,
}

const QuizB2BIntroduction: React.FunctionComponent<PageProps<unknown, CustomQuery>> = (props) => {
  return (
    <>
      <Row mt center="xs">
        <Col xs={8} sm={8} md={5} lg={4}>
          <RelativeWrapper>
            <GatsbyImage image={props.data.girl.childImageSharp.gatsbyImageData} alt="garota com máscara" />
          </RelativeWrapper>
        </Col>
      </Row>

      <Row mb center="xs">
        <Col xs={12} sm={10} md={9} lg={8}>
          <Separator />
          <H1 center>Qual é a pontuação do seu sono?</H1>
          <Separator />
          <Separator />
          <Body>
            Descubra o quão bom é o seu sono e veja uma dica de sono para você aplicar já hoje à noite! Fique tranquilo/a, as informações
            são confidenciais e armazenadas de forma segura.{' '}
            <span role="img" aria-label="segurança">
              &#128274; &#128077;
            </span>
          </Body>
        </Col>
      </Row>
    </>
  );
};

const consentToNumber = (value) => {
  switch (value) {
    case 'accept':
      return ConsentTerm.Accepted;
    case 'deny':
      return ConsentTerm.Rejected;
  }
};

const ConsentTermQuestion: React.FunctionComponent<any> = ({ consent, setConsent }) => {
  return (
    <>
      <Row mb center="xs">
        <Col xs={12} sm={10} md={9} lg={8}>
          {consent === ConsentTerm.Rejected && (
            <Row mb center="xs">
              <FlashMessage type={'alert'} dismissible={false} autoClose={false}>
                <Body>Não é possível prosseguir com o questionário de sono sem o aceite dos termos e políticas da Vigilantes do Sono.</Body>
              </FlashMessage>
            </Row>
          )}
          <Form.Field name="term" validators={[Validators.Required()]} onValueChange={(value) => setConsent(consentToNumber(value))}>
            <InputLabel>
              <TermTitle />
            </InputLabel>
            <Separator />
            <RadioField key={'accept'} id={'accept'}>
              Aceito
            </RadioField>
            <RadioField key={'deny'} id={'deny'}>
              Não aceito
            </RadioField>
          </Form.Field>
          <Separator />
        </Col>
      </Row>
    </>
  );
};

const NameQuestion: React.FunctionComponent<any> = () => {
  return (
    <>
      <Row mb center="xs">
        <Col xs={12} sm={10} md={9} lg={8}>
          <Form.Field name="name" validators={[Validators.Required()]}>
            <InputLabel>Qual o seu nome?</InputLabel>
            <TextField type={'text'} />
          </Form.Field>
          <Separator />
        </Col>
      </Row>
    </>
  );
};

const useDiagnosisMutation = (totalScore: string, age: SleepProblemAge, cause: SleepDisturb) => {
  return useMutationCustom(InsertB2bDiagnosisMutation, {
    onCompleted: (data: Mutation_Root) => {
      navigate(appPaths.b2bResults.path + '?score=' + totalScore + '&age=' + age + '&cause=' + cause);
    },
    client: defaultClient,
  });
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export default StartForm;

export const query = graphql`
  query StartQueryForm {
    site {
      siteMetadata {
        siteUrl
      }
    }
    girl: file(relativePath: { eq: "girl-with-mask.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 930, layout: CONSTRAINED)
      }
    }
  }
`;
