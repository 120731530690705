import { parseToDate } from '@global/utils/date';
import { HasuraScalarMappers } from '@global/utils/hasura/scalar-mappers';
import { B2b_Diagnosis_V1_Insert_Input } from '@global/utils/remote-graphql-types';
import { QuizB2BData } from '@lp-root/src/data/quiz-typeform.model';
import {
  mapAnxietyToNumber,
  mapConcernedToNumber,
  mapControlConcernsToNumber,
  mapDiscouragementToNumber,
  mapInitialInsomniaToNumber,
  mapInterferenceToNumber,
  mapMiddleInsomniaToNumber,
  mapOthersPerceptionToNumber,
  mapPleasureToNumber,
  mapSatisfactionToNumber,
  mapSleepDisturbToNumber,
  mapSleepProblemAgeToNumber,
  mapTerminalInsomniaToNumber,
  mapWeekFrequencyToNumber,
} from '@lp-root/src/modules/quiz-b2b/quiz-b2b-questions';

export const buildQuizB2BMutationVar = (input: QuizB2BData) => {
  const quizB2B: B2b_Diagnosis_V1_Insert_Input = {
    week_frequency: mapWeekFrequencyToNumber(input.weekFrequency),
    other_perception: mapOthersPerceptionToNumber(input.othersPerception),
    sleep_impact_on_daily_activities: mapInterferenceToNumber(input.interference),
    sleep_satisfaction: mapSatisfactionToNumber(input.satisfaction),
    concern_about_sleep: mapConcernedToNumber(input.concerned),
    initial_insomnia: mapInitialInsomniaToNumber(input.initialInsomnia),
    middle_insomnia: mapMiddleInsomniaToNumber(input.middleInsomnia),
    terminal_insomnia: mapTerminalInsomniaToNumber(input.terminalInsomnia),
    sleep_problem_duration: mapSleepProblemAgeToNumber(input.sleepProblemAge),
    sleep_disturbance: mapSleepDisturbToNumber(input.sleepDisturb),
    birthdate: HasuraScalarMappers.mapToDate(parseToDate(input.birthdate)),
    company: input.company,
    origin: input.origin,
    cpf: input.cpf,
    name: input.name,
    gender: input.gender,
    motivation: mapPleasureToNumber(input.pleasure),
    liveliness: mapDiscouragementToNumber(input.discouragement),
    anxiety: mapAnxietyToNumber(input.anxiety),
    self_control: mapControlConcernsToNumber(input.controlConcerns),
    extra_data: input.extraData ?? {},
  };

  return quizB2B;
};
